import React from "react"

const Testimonials = () => {

  return (
    <div id="testimonials">
    </div>
  )
}

export default Testimonials
